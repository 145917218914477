import type { SystemCssProperties } from '@mui/system'
import type { ColumnsOnOff } from '../ModuleSelectList'
import React, { useState, useEffect } from 'react'
import union from 'lodash.union'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { formatBoardOptionColumn, selectBoardOptionColumn } from '../BoardTableConfig'
import { positions as allPositions } from '../../../shared/utils/positions'
import Link from '@/components/Link'
import { firstInitialLastName } from '@/lib/utils/formatters'

type PositionBoardColumnsHeadersProps = {
    columns: DTO.BoardColumn[]
    activeColumns: ColumnsOnOff<Enum.BoardColumns>
    isRanked: boolean
}

const PositionBoardColumnHeader = ({
    label,
    styleOverrides = {},
}: {
    label: DTO.BoardColumn['title']
    styleOverrides?: SystemCssProperties
}) => (
    <Box component="th" sx={{ paddingX: '2px', textAlign: 'left', ...styleOverrides }}>
        {label}
    </Box>
)

const PositionBoardColumnsHeaders = ({ columns, activeColumns, isRanked }: PositionBoardColumnsHeadersProps) => {
    const defaultColumns = [
        <PositionBoardColumnHeader
            label="NAME"
            key="NAME"
            styleOverrides={{
                position: 'sticky',
                left: isRanked ? '17px' : 0,
                backgroundColor: 'grey.200',
                // @ts-expect-error valid css property
                '@media print': { backgroundColor: 'transparent' },
            }}
        />,
    ]
    if (isRanked)
        defaultColumns.unshift(
            <PositionBoardColumnHeader
                label="RK"
                key="RK"
                styleOverrides={{
                    position: 'sticky',
                    left: 0,
                    backgroundColor: 'grey.200',
                    // @ts-expect-error valid css property
                    '@media print': { backgroundColor: 'transparent' },
                }}
            />
        )
    const rest = columns
        .filter(({ type }) => activeColumns[type])
        .map(({ shortTitle: label }) => <PositionBoardColumnHeader key={label} label={label} />)

    return <>{defaultColumns.concat(rest)}</>
}

type PositionalBoardProps = {
    items: DTO.BoardData['items']
    isRanked: boolean
    isChildBoard: boolean
    activeColumns: ColumnsOnOff<Enum.BoardColumns>
    columns: DTO.BoardColumn[]
    measurementPercentiles: DTO.MeasurementPercentileMap | undefined
    boardId: string
}

const defaultPositions = allPositions
const positionTierHeight = '42px'
const positionTierHeightPrint = '25px'
const PositionalBoard = ({
    items,
    isRanked,
    isChildBoard,
    activeColumns,
    columns,
    measurementPercentiles,
    boardId,
}: PositionalBoardProps): JSX.Element => {
    const [positions, setPositions] = useState<string[]>(defaultPositions)
    useEffect(() => {
        const players = items.filter((p) => p.type === 'player') as DTO.BoardPlayer[]
        const posUnion = union(
            defaultPositions,
            players.map((p) => p.player.playerPosition)
        )
        setPositions(posUnion)
    }, [items])

    const tierPositions = items.reduce(
        (
            acc: {
                label: string | undefined
                position: number
                maxPlayersInPosition: number
                players: Record<string, DTO.BoardPlayer[] | undefined>
            }[],
            x
        ) => {
            if (x.type === 'team') return acc
            if (x.type === 'tier') {
                acc.push({ label: x.name, position: x.position, maxPlayersInPosition: 0, players: {} })
            } else {
                // insert "blank" tier for boards where there is no tier in the 1st position
                if (acc.length === 0) acc.push({ label: '', position: 0, maxPlayersInPosition: 0, players: {} })

                const tier = acc[acc.length - 1]
                const { playerPosition: position } = x.player
                if (!(position in tier.players)) tier.players[position] = []

                const positionPlayers = tier.players[position] as DTO.BoardPlayer[]
                positionPlayers.push(x)
                if (positionPlayers.length > tier.maxPlayersInPosition)
                    tier.maxPlayersInPosition = positionPlayers.length
            }
            return acc
        },
        []
    )

    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Box component="table" cellSpacing={0} sx={{ width: '100%', tableLayout: 'fixed' }}>
                <Box component="thead">
                    <Box component="tr">
                        {positions.map((p, idx) => (
                            <React.Fragment key={p}>
                                {idx > 0 && <Box component="td" sx={({ spacing }) => ({ width: spacing(1) })} />}
                                <Box
                                    component="th"
                                    sx={(theme) => ({
                                        fontWeight: 'normal',
                                        backgroundColor: 'grey.600',
                                        color: 'white',
                                        borderTopLeftRadius: theme.shape.borderRadius,
                                        borderTopRightRadius: theme.shape.borderRadius,
                                        height: positionTierHeight,
                                        width: theme.breakpoints.values.lg / 5 - 6,
                                        '@media print': {
                                            width: '100%',
                                            height: positionTierHeightPrint,
                                            fontSize: '14px',
                                        },
                                    })}
                                >
                                    {p}
                                </Box>
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
                <Box component="tbody">
                    {tierPositions.map((t) => (
                        <React.Fragment key={t.position}>
                            <>
                                {!!t.label && (
                                    <Box component="tr">
                                        <Box
                                            component="td"
                                            colSpan={positions.length * 2 - 1}
                                            sx={{
                                                position: 'relative',
                                                height: positionTierHeight,
                                                '@media print': {
                                                    height: positionTierHeightPrint,
                                                },
                                            }}
                                        >
                                            <Paper
                                                sx={{
                                                    backgroundColor: '#d8d8d8',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: positionTierHeight,
                                                    display: 'flex',
                                                    '@media print': {
                                                        boxShadow: 0,
                                                        height: positionTierHeightPrint,
                                                    },
                                                }}
                                                elevation={2}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        position: 'sticky',
                                                        lineHeight: positionTierHeight,
                                                        width: 300,
                                                        textAlign: 'center',
                                                        left: 'calc(50% - 150px)',
                                                        whiteSpace: 'nowrap',
                                                        '@media print': {
                                                            boxShadow: 0,
                                                            fontSize: '13px',
                                                            lineHeight: positionTierHeightPrint,
                                                        },
                                                    }}
                                                >
                                                    {t.label}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    </Box>
                                )}
                            </>
                            <Box component="tr">
                                {positions.map((p, idx) => (
                                    <React.Fragment key={p}>
                                        {idx > 0 && <td />}
                                        <Box
                                            component="td"
                                            sx={{
                                                overflowX: 'auto',
                                                verticalAlign: 'top',
                                                backgroundColor: 'grey.200',
                                                '@media print': { backgroundColor: 'transparent' },
                                                padding: 0,
                                            }}
                                        >
                                            <Box
                                                component="table"
                                                sx={{
                                                    verticalAlign: 'top',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <Box component="thead">
                                                    <Box component="tr" fontSize="12px" whiteSpace="nowrap">
                                                        <PositionBoardColumnsHeaders
                                                            columns={columns}
                                                            activeColumns={activeColumns}
                                                            isRanked={isRanked}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box component="tbody">
                                                    {t.players[p]?.map((pl) => (
                                                        <Box component="tr" key={pl.id}>
                                                            {isRanked && (!isChildBoard || pl.isRanked) && (
                                                                <Box
                                                                    component="td"
                                                                    fontSize="12px"
                                                                    textAlign="right"
                                                                    position="sticky"
                                                                    left={0}
                                                                    bgcolor="grey.200"
                                                                    paddingX="2px"
                                                                    sx={{
                                                                        '@media print': {
                                                                            backgroundColor: 'transparent',
                                                                            paddingY: '.5px',
                                                                        },
                                                                    }}
                                                                >
                                                                    {pl.rank}
                                                                </Box>
                                                            )}
                                                            <Box
                                                                component="td"
                                                                fontSize="12px"
                                                                position="sticky"
                                                                left={isRanked ? '17px' : 0}
                                                                bgcolor="grey.200"
                                                                paddingX="2px"
                                                                sx={{
                                                                    '@media print': {
                                                                        backgroundColor: 'transparent',
                                                                        paddingY: '.5px',
                                                                    },
                                                                }}
                                                            >
                                                                <Link
                                                                    href={`/teams/${pl.player.playerUrlSlug}/feed`}
                                                                    sx={{
                                                                        '@media print': {
                                                                            color: 'text.primary',
                                                                        },
                                                                    }}
                                                                >
                                                                    {firstInitialLastName(pl.name)}
                                                                </Link>
                                                            </Box>
                                                            {columns
                                                                .filter(({ type }) => activeColumns[type])
                                                                .map(({ type: key }) => (
                                                                    <Box
                                                                        key={key}
                                                                        component="td"
                                                                        fontSize="12px"
                                                                        whiteSpace="nowrap"
                                                                        sx={{
                                                                            paddingX: '2px',
                                                                            ...formatBoardOptionColumn({
                                                                                r: pl,
                                                                                key,
                                                                                view: 'position',
                                                                                measurementPercentiles,
                                                                            }),
                                                                            '@media print': {
                                                                                paddingY: '.5px',
                                                                            },
                                                                        }}
                                                                    >
                                                                        {selectBoardOptionColumn({
                                                                            r: pl,
                                                                            key,
                                                                            boardId,
                                                                            view: 'position',
                                                                        })}
                                                                    </Box>
                                                                ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                ))}
                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default PositionalBoard
