/* eslint-disable @typescript-eslint/ban-types */
import type { Dispatch, SetStateAction } from 'react'
import type { DeleteBoardItemFn } from '@/lib/hooks/useBoard'
import type { TableBodyProps } from './TableBody'
import type { DropResult, ResponderProvided } from '@hello-pangea/dnd'
import type { MovingAction } from '../boards/Board'
import React, { useState } from 'react'
import MuiTableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import MuiTableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import DeleteRowButton from '../boards/DeleteRowButton'
import ChildRankTable from '../boards/ChildRankTable'
import TableCell from './TableCell'
import EditTierNameInput from './EditTierNameInput'
import { defaultTableRowCss } from './styles'

type DnDTableBodyProps<T1 extends Object, T2 = null> = TableBodyProps<T1, T2> & {
    handleDragEnd: (result: DropResult, provided?: ResponderProvided) => void
    showMovingAction?: MovingAction
    setShowMovingAction?: Dispatch<SetStateAction<MovingAction>>
    handleMove?: (result: Record<string, unknown>) => void
    handleRemove?: DeleteBoardItemFn
    editMode: boolean
    boardData: DTO.BoardData
    selectedColumns: Enum.BoardColumns[] | undefined
}

const DnDTableBody = <T1 extends Object, T2 = null>({
    fields,
    rows,
    handleDragEnd,
    emptyValue,
    formatRow,
    getRowKey,
    setOpen,
    setInitialValues,
    showMovingAction,
    setShowMovingAction,
    handleRemove,
    editMode,
    boardData,
    selectedColumns,
}: DnDTableBodyProps<T1, T2>): JSX.Element => {
    const [showTierNameEdit, setShowTierNameEdit] = useState<DTO.BoardTier['id'] | null>(null)
    const [collapseRowOpen, setCollapseRowOpen] = React.useState<string | null>(null)
    const isChildRankBoard = !!boardData.parentId && !boardData.consensusType

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="column-1">
                {(droppableProvided, droppableSnapshot) => (
                    <MuiTableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                        {rows.map((r, i) => {
                            const row = r as unknown as DTO.BoardTier | DTO.BoardPlayer | DTO.BoardTeam
                            const rowFormat = typeof formatRow === 'function' ? formatRow(r, undefined, i) : formatRow
                            return (
                                <Draggable key={getRowKey(r)} draggableId={getRowKey(r) as string} index={i}>
                                    {(draggableProvided, snapshot) => (
                                        <React.Fragment key={getRowKey(r)}>
                                            <TableRow
                                                ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps}
                                                sx={{
                                                    ...draggableProvided.draggableProps.style,
                                                    backgroundColor:
                                                        row.type === 'tier'
                                                            ? 'grey.300'
                                                            : snapshot.isDragging
                                                            ? 'rgba(245,245,245, 0.75)'
                                                            : 'none',
                                                    position: row.type === 'tier' ? 'relative' : undefined,
                                                    zIndex: row.type === 'tier' ? 3 : undefined,
                                                    boxShadow: snapshot.isDragging ? 3 : row.type === 'tier' ? 2 : 0,
                                                    display: snapshot.isDragging ? 'table' : 'table-row',
                                                    maxWidth: snapshot.isDragging ? '1440px' : undefined,
                                                    '@media print': { boxShadow: 0 },
                                                    '.row-edit-action': {
                                                        display: 'none',
                                                    },
                                                    ...(showMovingAction?.id || droppableSnapshot.isDraggingOver
                                                        ? {
                                                              '.delete-board-row-button': {
                                                                  'pointer-events': 'none',
                                                                  opacity: 0.7,
                                                              },
                                                          }
                                                        : {
                                                              '&:hover': {
                                                                  '.row-edit-action': {
                                                                      display: 'block',
                                                                  },
                                                              },
                                                          }),
                                                    ...rowFormat,
                                                    ...defaultTableRowCss,
                                                }}
                                            >
                                                {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                                                {row.type === 'tier' && editMode && !isChildRankBoard ? (
                                                    <>
                                                        <MuiTableCell
                                                            colSpan={fields.length - 1}
                                                            sx={{ backgroundColor: 'grey.300' }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                {showTierNameEdit === row.id ? (
                                                                    <Box
                                                                        sx={{
                                                                            position: 'sticky',
                                                                            zIndex: 1,
                                                                            width: 300,
                                                                            textAlign: 'center',
                                                                            left: 'calc(50% - 150px)',
                                                                        }}
                                                                    >
                                                                        <EditTierNameInput
                                                                            boardId={boardData.boardId}
                                                                            selectedColumns={selectedColumns}
                                                                            version={boardData.version}
                                                                            tier={row}
                                                                            onClose={() => setShowTierNameEdit(null)}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            gap: 1,
                                                                            position: 'sticky',
                                                                            zIndex: 1,
                                                                            width: 350,
                                                                            justifyContent: 'center',
                                                                            left: 'calc(50% - 150px)',
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                position: 'relative',
                                                                                top: 3,
                                                                                cursor: 'grab',
                                                                            }}
                                                                            {...draggableProvided.dragHandleProps}
                                                                        >
                                                                            <DragHandleIcon />
                                                                        </Box>
                                                                        <Typography variant="subtitle2">
                                                                            {row.name}
                                                                        </Typography>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => setShowTierNameEdit(row.id)}
                                                                        >
                                                                            <EditIcon
                                                                                color="primary"
                                                                                fontSize="inherit"
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </MuiTableCell>
                                                        {handleRemove && (
                                                            <MuiTableCell
                                                                sx={{
                                                                    position: 'sticky',
                                                                    zIndex: 1,
                                                                    paddingRight: 0,
                                                                    right: 0,
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                <DeleteRowButton
                                                                    id={row.id}
                                                                    type={row.type}
                                                                    handleRemove={handleRemove}
                                                                    isDragging={snapshot.isDragging}
                                                                    style={{ marginY: -10 }}
                                                                    tier
                                                                />
                                                            </MuiTableCell>
                                                        )}
                                                    </>
                                                ) : row.type === 'tier' ? (
                                                    <MuiTableCell
                                                        colSpan={fields.length}
                                                        sx={{ backgroundColor: 'grey.300', height: 42 }}
                                                    >
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box
                                                                sx={{
                                                                    position: 'sticky',
                                                                    zIndex: 1,
                                                                    width: 300,
                                                                    textAlign: 'center',
                                                                    left: 'calc(50% - 150px)',
                                                                }}
                                                            >
                                                                <Typography variant="subtitle2">{row.name}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </MuiTableCell>
                                                ) : (
                                                    fields.map(
                                                        ({ key, select, format, numeric, header, label }, idx, self) =>
                                                            idx === 0 && boardData.graders.length > 0 && !editMode ? (
                                                                <MuiTableCell
                                                                    sx={{
                                                                        '@media print': {
                                                                            display: 'none',
                                                                        },
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            setCollapseRowOpen(
                                                                                collapseRowOpen === row.id
                                                                                    ? null
                                                                                    : row.id
                                                                            )
                                                                        }
                                                                    >
                                                                        {collapseRowOpen === row.id ? (
                                                                            <KeyboardArrowUpIcon />
                                                                        ) : (
                                                                            <KeyboardArrowDownIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </MuiTableCell>
                                                            ) : idx === 0 && editMode ? (
                                                                <MuiTableCell
                                                                    key={key}
                                                                    sx={{
                                                                        width: 80,
                                                                        position: 'sticky',
                                                                        zIndex: 1,
                                                                        backgroundColor: snapshot.isDragging
                                                                            ? 'none'
                                                                            : 'common.white',
                                                                        left: 0,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            cursor: 'grab',
                                                                        }}
                                                                        {...draggableProvided.dragHandleProps}
                                                                    >
                                                                        <DragHandleIcon />
                                                                    </Box>
                                                                </MuiTableCell>
                                                            ) : idx === 1 && editMode ? (
                                                                <MuiTableCell
                                                                    key={key}
                                                                    sx={{
                                                                        width: 70,
                                                                        position: 'sticky',
                                                                        backgroundColor: snapshot.isDragging
                                                                            ? 'none'
                                                                            : 'common.white',
                                                                        left: 56,
                                                                        zIndex: 2,
                                                                    }}
                                                                >
                                                                    {showMovingAction?.id === row.id ? (
                                                                        <Button
                                                                            onClick={() =>
                                                                                setShowMovingAction?.({
                                                                                    id: null,
                                                                                    index: null,
                                                                                })
                                                                            }
                                                                            color="error"
                                                                            size="small"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontSize: '12px',
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    ) : showMovingAction?.id ? (
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleDragEnd(
                                                                                    {
                                                                                        draggableId:
                                                                                            showMovingAction.id,
                                                                                        source: {
                                                                                            droppableId: 'column-1',
                                                                                            index: showMovingAction.index,
                                                                                        },
                                                                                        destination: {
                                                                                            droppableId: 'column-1',
                                                                                            index: i,
                                                                                        },
                                                                                        reason: 'DROP',
                                                                                        mode: 'FLUID',
                                                                                        type: '',
                                                                                        combine: null,
                                                                                    },
                                                                                    undefined
                                                                                )
                                                                            }
                                                                            size="small"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontSize: '12px',
                                                                            }}
                                                                        >
                                                                            Here
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            className="row-edit-action"
                                                                            onClick={() =>
                                                                                setShowMovingAction?.({
                                                                                    id: row.id,
                                                                                    index: i,
                                                                                })
                                                                            }
                                                                            size="small"
                                                                            variant="outlined"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                transform: 'translate(-50%, -50%)',
                                                                                fontSize: '12px',
                                                                            }}
                                                                        >
                                                                            Move
                                                                        </Button>
                                                                    )}
                                                                </MuiTableCell>
                                                            ) : !editMode && key !== 'controlsMenu' && !label ? null : (
                                                                <TableCell
                                                                    row={r}
                                                                    rows={rows}
                                                                    key={key}
                                                                    index={i}
                                                                    select={select}
                                                                    format={format}
                                                                    numeric={numeric}
                                                                    emptyValue={emptyValue}
                                                                    borderLeft={
                                                                        idx > 0 &&
                                                                        !!self[idx - 1].header &&
                                                                        header !== self[idx - 1].header
                                                                    }
                                                                    tableProps={{
                                                                        setOpen,
                                                                        setInitialValues,
                                                                        isDragging: snapshot.isDragging,
                                                                    }}
                                                                />
                                                            )
                                                    )
                                                )}
                                            </TableRow>
                                            {(row.type === 'player' || row.type === 'team') && row.avgRank && (
                                                <TableRow
                                                    sx={{
                                                        '@media print': {
                                                            display: 'none',
                                                        },
                                                    }}
                                                >
                                                    <MuiTableCell
                                                        style={{ paddingBottom: 0, paddingTop: 0 }}
                                                        colSpan={fields.length}
                                                    >
                                                        <Collapse
                                                            in={collapseRowOpen === row.id}
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <ChildRankTable
                                                                overallRank={row.rank}
                                                                rows={row.childRanks}
                                                                isRankedParent={boardData.isRanked}
                                                                isConsensusBoard={boardData.isConsensus}
                                                            />
                                                        </Collapse>
                                                    </MuiTableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Draggable>
                            )
                        })}
                        {droppableProvided.placeholder}
                    </MuiTableBody>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DnDTableBody
