import type { SystemCssProperties } from '@mui/system'
import type { DeleteBoardItemFn } from '@/lib/hooks/useBoard'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type DeleteBoardRowButtonProps = Parameters<DeleteBoardItemFn>[0] & {
    handleRemove: DeleteBoardItemFn
    isDragging: boolean
    tier?: boolean
    style?: SystemCssProperties | undefined
}

const DeleteBoardRowButton = ({
    handleRemove,
    id,
    type,
    tier,
    isDragging,
    style,
}: DeleteBoardRowButtonProps): JSX.Element => (
    <IconButton
        sx={{
            color: 'grey',
            '&:hover': { color: 'error.main', backgroundColor: tier ? 'grey.300' : 'common.white' },
            backgroundColor: tier ? 'grey.300' : isDragging ? 'rgba(245,245,245, 0.75)' : 'common.white',
            ...style,
        }}
        className="delete-board-row-button"
        onClick={() => handleRemove({ id, type })}
    >
        <HighlightOffIcon />
    </IconButton>
)

export default DeleteBoardRowButton
