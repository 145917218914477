import type { Dispatch, MouseEvent } from 'react'
import type { SwitchProps } from '@mui/material/Switch'
import type { ModuleExpandedList } from '@/lib/hooks/useBoard'
import React, { useState, useCallback } from 'react'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useWatchVariable } from '@/lib/hooks'
import { useModuleColumns } from '@/lib/hooks/useBoard'

const stopPropagation: Required<SwitchProps>['onClick'] = (e) => e.stopPropagation()

export type ColumnsOnOff<T extends string> = {
    [column in T]?: boolean
}

type ModuleExpandedListProps<T extends string, T2 extends string> = ModuleExpandedList<T, T2> & {
    selectedColumns: ColumnsOnOff<T>
    handleModuleToggle: Required<SwitchProps>['onChange']
    handleColumnToggle: Required<SwitchProps>['onChange']
}
const ModuleExpandedListItem = <T extends string, T2 extends string>({
    name,
    title,
    columns,
    selectedColumns,
    handleModuleToggle,
    handleColumnToggle,
}: ModuleExpandedListProps<T, T2>) => {
    const [isOpen, setIsOpen] = useState(false)

    const numColumnsSelected = columns.filter((c) => selectedColumns[c.type]).length
    let primary = title
    if (numColumnsSelected) primary += ` (${numColumnsSelected})`

    return (
        <>
            <ListItemButton onClick={() => setIsOpen(!isOpen)}>
                <ListItemIcon sx={{ minWidth: 40 }}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                <ListItemText id={`switch-${name}`} primary={primary} sx={{ mr: 1 }} />
                <Switch
                    size="small"
                    edge="end"
                    name={name}
                    onChange={handleModuleToggle}
                    checked={!!numColumnsSelected}
                    onClick={stopPropagation}
                />
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <MenuList dense>
                    {columns.map((c) => (
                        <ListItem key={String(c.type)} sx={{ pl: 4 }}>
                            <ListItemText primary={c.title} sx={{ mr: 1 }} />
                            <Switch
                                size="small"
                                edge="end"
                                name={String(c.type)}
                                onChange={handleColumnToggle}
                                checked={!!selectedColumns[c.type]}
                            />
                        </ListItem>
                    ))}
                </MenuList>
                <Divider />
            </Collapse>
        </>
    )
}

type ModuleSelectListProps<T extends string, T2 extends string> = {
    columns: DTO.ModuleSelect<T, T2>[]
    activeColumns: ColumnsOnOff<T>
    setActiveColumns: Dispatch<ColumnsOnOff<T>>
    moduleLabels: Readonly<Record<T2, string>>
}
function ModuleSelectList<T extends string, T2 extends string>({
    columns,
    activeColumns,
    setActiveColumns,
    moduleLabels,
}: ModuleSelectListProps<T, T2>): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedColumns, setSelectedColumns] = useState<typeof activeColumns>(activeColumns)
    useWatchVariable(setSelectedColumns, activeColumns, (a, b) => a === b)

    const isSelectionModified = selectedColumns !== activeColumns
    const handleClose = useCallback(() => {
        // only update the active columns when the menu is closed
        if (isSelectionModified) setActiveColumns(selectedColumns)
        setAnchorEl(null)
    }, [isSelectionModified, selectedColumns, setActiveColumns, setAnchorEl])
    const handleOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget), [setAnchorEl])

    const [modules, groups] = useModuleColumns<T, T2>({ filteredColumns: columns, moduleLabels })

    const handleModuleToggle = useCallback<ModuleExpandedListProps<T, T2>['handleModuleToggle']>(
        (e, isChecked) => {
            const moduleInfo = modules[e.target.name as T2]
            setSelectedColumns((state) => ({
                ...state,
                ...moduleInfo.columns.reduce(
                    (acc, c) => ({
                        ...acc,
                        [c.type as keyof T]: isChecked,
                    }),
                    {}
                ),
            }))
        },
        [modules, setSelectedColumns]
    )
    const handleColumnToggle = useCallback<ModuleExpandedListProps<T, T2>['handleColumnToggle']>(
        (e, isChecked) => {
            const column = e.target.name as Enum.BoardColumns
            setSelectedColumns((state) => ({
                ...state,
                [column]: isChecked,
            }))
        },
        [setSelectedColumns]
    )

    const open = !!anchorEl
    return (
        <>
            <Tooltip title="Manage Columns">
                <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpen}
                >
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    dense: true,
                    sx: { minWidth: 250, maxHeight: 500, overflowY: 'auto' },
                }}
                disableScrollLock
            >
                {groups.map(([m, props]) => (
                    <ModuleExpandedListItem
                        key={m}
                        handleModuleToggle={handleModuleToggle}
                        handleColumnToggle={handleColumnToggle}
                        selectedColumns={selectedColumns}
                        {...props}
                    />
                ))}
            </Menu>
        </>
    )
}

export default ModuleSelectList
