import type { FormikProps, FormikConfig } from 'formik'
import type { UpdateBoardParams } from '@/lib/hooks/useBoard'
import type TableConfig from '@/lib/types/tableConfigTypes'
import React, { useState, useCallback, useMemo } from 'react'
import { Formik } from 'formik'
import { index } from 'd3-array'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import LoadingButton from '@mui/lab/LoadingButton'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled'
import { okaidiaTextColor, SQLCodeEditor } from '../form/CodeEditor'
import Table from '../Table'
import { useSQLQuery, useSQLImport } from '@/lib/hooks/useBoard'

type SubmitQueryButtonProps = UpdateBoardParams &
    Pick<FormikProps<DTO.SQLImport>, 'values' | 'setFieldValue'> & {
        isLoading: boolean
        setScriptData: React.Dispatch<React.SetStateAction<DTO.SQLQueryResp | boolean | string>>
        entityType: Enum.BoardEntityType
        selectedColumns: Enum.BoardColumns[] | undefined
    }
const SubmitQueryButton = ({
    boardId,
    version,
    values,
    isLoading,
    setScriptData,
    setFieldValue,
    entityType,
    selectedColumns,
}: SubmitQueryButtonProps) => {
    const { mutateAsync: queryScript } = useSQLQuery({ boardId, selectedColumns, version })
    const onQueryScriptData = useCallback(
        async ({ sqlScript, serverPassword }: DTO.SQLImport) => {
            setScriptData(true)
            try {
                const resp = await queryScript({ sqlScript, serverPassword })
                setScriptData(resp.data)
            } catch (e) {
                setScriptData((e as Error).message)
            } finally {
                await Promise.all([setFieldValue('doNotAdd', []), setFieldValue('doNotDelete', [])])
            }
        },
        [setScriptData, setFieldValue, queryScript]
    )

    const SQL = values.sqlScript.toUpperCase()
    const canSubmitCode =
        !isLoading &&
        !!values.serverPassword &&
        SQL.includes('SELECT') &&
        SQL.includes('FROM') &&
        SQL.includes(entityType === 'TEAM' ? 'TEAM_ID' : 'PLAYER_ID')
    return (
        <IconButton
            type="button"
            onClick={() => onQueryScriptData(values)}
            size="large"
            disabled={!canSubmitCode}
            sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                '&&': { color: okaidiaTextColor },
            }}
        >
            {canSubmitCode ? <PlayArrowIcon /> : <PlayDisabledIcon />}
        </IconButton>
    )
}

type ScriptDataCheckboxProps = Pick<DTO.SQLImportItem, 'entityId' | 'tier'> &
    Pick<FormikProps<DTO.SQLImport>, 'setFieldValue'> & {
        name: string
        isChecked: boolean
        unchecked: Pick<DTO.SQLImportItem, 'entityId' | 'tier'>[]
    }
const ScriptDataCheckbox = ({ entityId, tier, name, isChecked, unchecked, setFieldValue }: ScriptDataCheckboxProps) => (
    <Checkbox
        sx={{ p: '1px' }}
        name={name}
        checked={isChecked}
        onChange={() =>
            setFieldValue(
                name,
                isChecked
                    ? [...unchecked, { entityId, tier }]
                    : unchecked.filter((x) => x.tier !== tier || x.entityId !== entityId)
            )
        }
    />
)

type ToBeAddedTableProps = Pick<ScriptDataCheckboxProps, 'setFieldValue'> & {
    doNotAddValue: DTO.SQLImport['doNotAdd']
    scriptData: true | DTO.SQLQueryResp
    isLoading: boolean
}
const ToBeAddedTable = ({ scriptData, doNotAddValue, setFieldValue, isLoading }: ToBeAddedTableProps): JSX.Element => {
    const tableConfigAdded = useMemo<TableConfig<DTO.SQLQueryResp['added'][number]>>(() => {
        const doNotAdd = doNotAddValue || []
        const uncheckedLk = index(
            doNotAdd,
            (x) => x.tier,
            (x) => x.entityId
        )
        return {
            loadingSkeleton: {
                height: 300,
                numOfRows: 10,
            },
            fields: [
                { key: 'rank', label: 'Rank', select: (x) => x.rank, skeletonStyle: { xs: 50 } },
                { key: 'name', label: 'Name', select: (x) => x.name, skeletonStyle: { xs: 100 } },
                { key: 'tier', label: 'Tier', select: (x) => x.tier, skeletonStyle: { xs: 100 } },
                {
                    key: 'remove',
                    label: typeof scriptData === 'object' && (
                        <Checkbox
                            sx={{ p: 0 }}
                            name="doNotAdd"
                            checked={!doNotAdd.length}
                            onChange={() =>
                                setFieldValue(
                                    'doNotAdd',
                                    doNotAdd.length
                                        ? []
                                        : scriptData.added.map(({ entityId, tier }) => ({ entityId, tier }))
                                )
                            }
                        />
                    ),
                    // eslint-disable-next-line react/no-unstable-nested-components
                    select: ({ entityId, tier }) =>
                        typeof scriptData === 'object' && (
                            <ScriptDataCheckbox
                                entityId={entityId}
                                tier={tier}
                                name="doNotAdd"
                                isChecked={!uncheckedLk.get(tier)?.has(entityId)}
                                setFieldValue={setFieldValue}
                                unchecked={doNotAdd}
                            />
                        ),
                    skeletonStyle: { xs: 50 },
                },
            ],
        }
    }, [scriptData, setFieldValue, doNotAddValue])
    return (
        <Table
            rows={scriptData === true ? [] : scriptData.added}
            config={tableConfigAdded}
            isLoading={isLoading}
            getRowKey={(x) => x.id}
            stickyHeader
            height={300}
        />
    )
}

type ToBeDeletedTableProps = Pick<FormikProps<DTO.SQLImport>, 'setFieldValue'> & {
    doNotDeleteValue: DTO.SQLImport['doNotDelete']
    scriptData: true | DTO.SQLQueryResp
    isLoading: boolean
}
const ToBeDeletedTable = ({
    scriptData,
    doNotDeleteValue,
    setFieldValue,
    isLoading,
}: ToBeDeletedTableProps): JSX.Element => {
    const tableConfigDeleted = useMemo<TableConfig<DTO.SQLQueryResp['deleted'][number]>>(() => {
        const doNotDelete = doNotDeleteValue || []
        const uncheckedLk = index(
            doNotDelete,
            (x) => x.tier,
            (x) => x.entityId
        )
        return {
            loadingSkeleton: {
                height: 300,
                numOfRows: 10,
            },
            fields: [
                { key: 'name', label: 'Name', select: (x) => x.name, skeletonStyle: { xs: 100 } },
                { key: 'tier', label: 'Tier', select: (x) => x.tier, skeletonStyle: { xs: 100 } },
                {
                    key: 'remove',
                    label: typeof scriptData === 'object' && (
                        <Checkbox
                            sx={{ p: 0 }}
                            name="doNotDelete"
                            checked={!doNotDelete.length}
                            onChange={() =>
                                setFieldValue(
                                    'doNotDelete',
                                    doNotDelete.length
                                        ? []
                                        : scriptData.deleted.map(({ entityId, tier }) => ({ entityId, tier }))
                                )
                            }
                        />
                    ),
                    // eslint-disable-next-line react/no-unstable-nested-components
                    select: ({ entityId, tier }) =>
                        typeof scriptData === 'object' ? (
                            <ScriptDataCheckbox
                                entityId={entityId}
                                tier={tier}
                                name="doNotDelete"
                                isChecked={!uncheckedLk.get(tier)?.has(entityId)}
                                setFieldValue={setFieldValue}
                                unchecked={doNotDelete}
                            />
                        ) : null,
                    skeletonStyle: { xs: 50 },
                },
            ],
        }
    }, [scriptData, setFieldValue, doNotDeleteValue])
    return (
        <Table
            rows={scriptData === true ? [] : scriptData.deleted}
            config={tableConfigDeleted}
            isLoading={isLoading}
            getRowKey={(x) => x.id}
            stickyHeader
            height={300}
        />
    )
}

type SQLPopulateFormProps = UpdateBoardParams & {
    handleDiscard: () => void
    initialValues: DTO.SQLImport
    entityType: Enum.BoardEntityType
}
const SQLPopulateForm = ({
    boardId,
    version,
    selectedColumns,
    initialValues,
    entityType,
    handleDiscard,
}: SQLPopulateFormProps): JSX.Element => {
    const [scriptData, setScriptData] = useState<DTO.SQLQueryResp | boolean | string>(false)

    const { mutateAsync: submitScript } = useSQLImport({ boardId, version })
    const onSubmit = useCallback<FormikConfig<DTO.SQLImport>['onSubmit']>(
        async (values) => {
            try {
                await submitScript(values)
                handleDiscard()
            } catch (e) {
                setScriptData((e as Error).message)
            }
        },
        [submitScript, handleDiscard]
    )

    const isLoading = scriptData === true
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, handleChange, setFieldValue, isSubmitting, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container direction="column">
                        <DialogTitle>
                            <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                                <Box sx={{ whiteSpace: 'nowrap', mr: 1 }}>Populate Board</Box>
                                <TextField
                                    size="small"
                                    name="serverPassword"
                                    placeholder="SQL Server Password"
                                    label="Password"
                                    required
                                    onChange={handleChange}
                                    value={values.serverPassword}
                                    type="password"
                                    sx={{ width: '100%', maxWidth: 250 }}
                                />
                            </Stack>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ position: 'relative', mb: 2 }}>
                                <SQLCodeEditor
                                    name="sqlScript"
                                    value={values.sqlScript}
                                    onValueChange={(code) => setFieldValue('sqlScript', code)}
                                    disabled={isLoading || isSubmitting}
                                />
                                <SubmitQueryButton
                                    boardId={boardId}
                                    version={version}
                                    isLoading={isLoading || isSubmitting}
                                    selectedColumns={selectedColumns}
                                    values={values}
                                    entityType={entityType}
                                    setScriptData={setScriptData}
                                    setFieldValue={setFieldValue}
                                />
                                <Typography variant="caption">
                                    Note: Queries must explicitly select a{' '}
                                    <b>{entityType === 'TEAM' ? 'team_id' : 'player_id'}</b> column and an [optional]{' '}
                                    <b>tier</b> column.
                                </Typography>
                            </Box>
                            {(typeof scriptData === 'object' || scriptData === true) && (
                                <Stack direction="row" spacing={2}>
                                    <Box width="100%">
                                        <Typography variant="h6" fontSize={16}>
                                            To Be Added
                                        </Typography>
                                        <ToBeAddedTable
                                            scriptData={scriptData}
                                            doNotAddValue={values.doNotAdd}
                                            setFieldValue={setFieldValue}
                                            isLoading={isLoading}
                                        />
                                    </Box>
                                    <Box width="100%">
                                        <Typography variant="h6" fontSize={16}>
                                            To Be Deleted
                                        </Typography>
                                        <ToBeDeletedTable
                                            scriptData={scriptData}
                                            doNotDeleteValue={values.doNotDelete}
                                            setFieldValue={setFieldValue}
                                            isLoading={isLoading}
                                        />
                                    </Box>
                                </Stack>
                            )}
                            {typeof scriptData === 'string' && (
                                <Box
                                    component="pre"
                                    sx={(theme) => ({
                                        fontSize: 13,
                                        p: 1,
                                        mb: 0,
                                        background: theme.palette.callOut.alert,
                                        whiteSpace: 'pre-wrap',
                                    })}
                                >
                                    {scriptData}
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDiscard} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                loading={isSubmitting}
                                disabled={
                                    isLoading ||
                                    isSubmitting ||
                                    !values.serverPassword ||
                                    !(initialValues.sqlScript || typeof scriptData === 'object')
                                }
                            >
                                Submit
                            </LoadingButton>
                        </DialogActions>
                    </Grid>
                </form>
            )}
        </Formik>
    )
}

type SQLPopulateDialogProps = Omit<SQLPopulateFormProps, 'handleDiscard' | 'initialValues'> & {
    isOpen: boolean
    handleDialogClose: SQLPopulateFormProps['handleDiscard']
    sqlScript: string | null
}
const SQLPopulateDialog = ({ isOpen, handleDialogClose, sqlScript, ...rest }: SQLPopulateDialogProps): JSX.Element => (
    <Dialog fullWidth open={isOpen} onClose={handleDialogClose} maxWidth="md">
        <SQLPopulateForm
            handleDiscard={handleDialogClose}
            initialValues={{ sqlScript: sqlScript || '', serverPassword: '' }}
            {...rest}
        />
    </Dialog>
)

export default SQLPopulateDialog
