import Dialog from '@mui/material/Dialog'
import React from 'react'
import { useFormik } from 'formik'
import BoardItemNotesForm from './BoardItemNotesForm'
import { useUpdateBoardTeamNotes } from '@/lib/hooks/useBoard'
import useToastContext from '@/lib/hooks/useToastContext'

type BoardTeamNotesDialogProps = {
    open: boolean
    initialValues: DTO.BoardTeam
    selectedColumns: Enum.BoardColumns[] | undefined
    setOpen: React.Dispatch<React.SetStateAction<DTO.BoardTeam | undefined>>
    boardId: string
}

const BoardTeamNotesDialog = ({
    open,
    initialValues,
    selectedColumns,
    setOpen,
    boardId,
}: BoardTeamNotesDialogProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutateAsync } = useUpdateBoardTeamNotes(boardId, selectedColumns, initialValues.id, toastContext)

    const formik = useFormik<DTO.BoardTeam>({
        onSubmit: async (values) => {
            await mutateAsync(values)
            setOpen(undefined)
        },
        initialValues,
    })

    return (
        <Dialog open={open}>
            <BoardItemNotesForm formik={formik} setOpen={setOpen} />
        </Dialog>
    )
}

export default BoardTeamNotesDialog
